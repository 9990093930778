import React, { useContext } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "components/layout";

import Seo from "components/seo";
import PageHeader from "components/PageHeader";
import useTranslations from 'hooks/useTranslations';
import CardGroup from "components/investigations-page/CardGroup";

const Blog = ({ data }) => {
  const tr = useTranslations();
  const posts = data.blogposts.edges.map(p => ({
    ...p.node,
    type: tr("Blog")
  }));
  const status = `${posts.length} ${tr("Blog posts")}`;
  const metadata = { title: "blog" };
  return (
    <Layout>
      <Seo metadata={metadata} />
      <PageHeader
        title="Blog"
        desc="config.blog.description"
        status={status}
      />
      <CardGroup items={posts} />
    </Layout>
  );
}

Blog.propTypes = {
  data: PropTypes.object,
};

export default Blog;

export const pageQuery = graphql`
  query($locale: String!) {
    site {
      siteMetadata {
        title
      }
    }
    blogposts: allStrapiBlog(
      filter: {
        locale: {eq: $locale},
        publishedAt: {ne: null}
      }
      sort: {fields: date, order: DESC}
    ) {
      edges {
        node {
          slug
          locale
          title
          date
          desc
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 260
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    }
  }
`;
